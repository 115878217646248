// import * as React from 'react';
import React, { useRef, useEffect, useState } from 'react';
import styles from './navbar.component.module.scss';
import { ReactComponent as ZirohLogoDark } from '../../../assets/svg/zirohLogoDark.svg';
import { ReactComponent as Ham } from '../../../assets/svg/ham.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/icon_close_black.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/arrow-down.svg';
import { Link, Route, useLocation } from 'react-router-dom';
import Button from '../Button/button.component';
import useNavbarHook from './navbar.hook';
import { DEVICE, PLATFORM, THEME } from '../../constants/constants';
import { useDeviceStore } from '../../../StateManager/DeviceStore';
import { OutsideClickHandler } from '../OutsideClickHandler/OutsideClickHandler';
import { AnimatePresence, motion } from 'framer-motion';
import { ROUTES } from '../../constants/routes';

const variants = {
	open: {
		x: '0vw',
		transition: {
			x: { stiffness: 200 }
		}
	},

	closed: {
		x: '100vw'
	},

	exit: {
		x: '80vw'
	}
};


export default function Navbar() {

	const navbarRef = useRef<any>(null);
	const [navbarHeight, setNavbarHeight] = useState<number>(0);

	useEffect(() => {
		if (navbarRef.current) {
			const initialHeight = navbarRef.current.offsetHeight;
			setNavbarHeight(initialHeight);
			console.log('initial Navbar height:', initialHeight);

			const resizeObserver = new ResizeObserver(entries => {
				for (let entry of entries) {
					const height = entry.target.clientHeight;
					const heightInrem = height / 16;
					setNavbarHeight(heightInrem);
					document.documentElement.style.setProperty('--nav-height', `${heightInrem}rem`)
					console.log('Navbar height:', heightInrem);
				}
			});

			resizeObserver.observe(navbarRef.current);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, []);




	const {
		isSideMenu,
		handleHamClick,
		closeSideMenu,
	} = useNavbarHook();

	const { device } = useDeviceStore();

	const location = useLocation();

	return (
		<nav className={`${styles.navbar}`} ref={navbarRef} >
			<div className={styles.logoContainer}>
				<Link to={ROUTES.HOME} reloadDocument>
					<ZirohLogoDark className={styles.logo} />
				</Link>
			</div>

			<div className={styles.navOptions}>
				{device == DEVICE.DESKTOP && (
					<div className={styles.navLinks}>
						<Link
							to={ROUTES.INDIVIDUALS}
							reloadDocument
							className={styles.navOption}
							target="_blank"
						>
							Individuals
						</Link>

						<Link
							to={ROUTES.ENTERPRISE}
							reloadDocument
							className={styles.navOption}
							target="_blank"
						>
							Enterprise
						</Link>

						<Link
							to={ROUTES.ABOUT_US}
							reloadDocument
							className={styles.navOption}
						>
							About Us
						</Link>

						{/* <Link
								to={ROUTES.SUPPORT}
								reloadDocument
								className={styles.navOption}
								target="_blank"
							>
								Resources & Support
							</Link> */}
					</div>
				)}

				<div className={styles.demo_button_container}>
					{/* <Link
						reloadDocument
						to={`${ROUTES.CONTACT_US}`}
						target='_blank'
					// className={styles.downloadButtonContainer}
					>
						<Button type="secondary" text="Contact Us" />
					</Link> */}

					{device !== DEVICE.DESKTOP && (
						<Ham
							className={styles.ham}
							onClick={handleHamClick}
						/>
					)}
				</div>
			</div>
			{/* )} */}
			<AnimatePresence>
				{isSideMenu && (
					<motion.div className={styles.sideMenuContainer}>
						<div className={styles.sideMenuWrapper}>
							<OutsideClickHandler
								onOutsideClick={() => {
									closeSideMenu();
								}}
							>
								<motion.div
									variants={variants}
									initial={'closed'}
									animate={isSideMenu ? 'open' : 'closed'}
									exit="exit"
									className={isSideMenu ? `${styles.sideMenu} ${styles.sideMenuActive}` : `${styles.sideMenu}`}
								>
									<CloseIcon
										className={styles.menuCloseIcon}
										onClick={closeSideMenu}
									/>
									<div className={styles.menu}>

										<Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.INDIVIDUALS}
											target="_blank"
										>
											Individuals
										</Link>

										<hr />

										<Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.ENTERPRISE}
											target="_blank"
										>
											Enterprise
										</Link>

										<hr />

										<Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.ABOUT_US}
										>
											About Us
										</Link>
										<hr />
									</div>
								</motion.div>
							</OutsideClickHandler>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</nav >
	);
}
